import { useState } from 'react';
import TitleBar from '../title-bar/title-bar';
import { useMarkdown } from '../../providers/markdown-provider';
import './editor.css';
import { Octokit } from "@octokit/core";
import { Buffer } from 'buffer';
import { useNotifications } from '../../providers/NotificationContext';

const octokit = new Octokit({ auth: process.env.REACT_APP_OCTOKIT_API_KEY });

const Editor = (props) => {
 const [markdown, setMarkdown] = useMarkdown();
 const [words, setWords] = useState(0);
 const [chars, setChars] = useState(0);


 const { createNotification } = useNotifications();


 const getWordsCount = (str) => {
  try {
   return str.match(/(\w+)/g).length;
  } catch (e) {
   return 0;
  }
 };


 const getCharsCount = (str) => {
  try {
   return str.length;
  } catch (e) {
   return 0;
  }
 };


 const processFile = () => {
  // ask for user name through prompt
  createBranchAndUploadFile(markdown);
 };

 const createBranchAndUploadFile = async (body) => {

  // disable the button
  document.querySelector('#Submission').disabled = true;
  // change the button text
  document.querySelector('#Submission').innerHTML = "Submitting...";

  // get date and time
  var date = new Date().getTime();
  //bas64 encode the body
  const blog = Buffer.from(body).toString('base64');

  // use await code
  // create branch - create file - create pull request

  let branch;

  try {

   await octokit.request('PUT /repos/{owner}/{repo}/contents/{path}', {
    owner: 'sbk2k1',
    repo: 'brainproblems',
    path: `news/sbk2k1-${date}.md`,
    branch: `master`,
    message: `Article Submission by sbk2k1 on ${date}`,
    committer: {
     email: "bhattacharyasaptarshi2001@gmail.com",
     name: "sbk2k1"
    },
    content: blog,
    headers: {
     'X-GitHub-Api-Version': '2022-11-28'
    }
   });
   // refresh the page
   window.location.reload();
  } catch (err) {
   
   createNotification('error', err.message, "Error");

   // enable the button
   document.querySelector('#Submission').disabled = false;
   // change the button text
   document.querySelector('#Submission').innerHTML = "Submit!";
  }
 }

 const updateMarkdown = (event) => {
  const value = event.target.value;

  setMarkdown(value);
  setWords(getWordsCount(value));
  setChars(getCharsCount(value));
 };

 return (
  <div className="editor__wrap">
   <TitleBar title="Editor" aside={`${words} Words ${chars} Characters`} />
   <textarea
    className="editor"
    value={markdown}
    onChange={updateMarkdown}
   />
   <button onClick={processFile} id="Submission">Submit!</button>
  </div>
 );
}

export default Editor;
