import { GoogleLogin } from 'react-google-login';
import { useState } from 'react';
// notification
import { useNotifications } from '../providers/NotificationContext';
import { writers } from '../data/allowed';

export const Header = (props) => {

  const { createNotification } = useNotifications();
  // first time button text is 'Write a Blog Yourself' if url has no code
  const [visibility, setVisibility] = useState(true);
  const [toggle, setToggle] = useState(false);


  function handleSuccess(response) {

    // if user not present in writers list then show error
    if (!writers.includes(response.profileObj.email)) {
      createNotification('error', 'You are not allowed to write a blog!');

      // set visibility to true
      setVisibility(true);
      return;
    }

    // create success notification
    createNotification('success', 'Successfully logged in as ' + response.profileObj.email);

    // set visibility to false
    setVisibility(false);

    // show editor
    props.toggleFunction(true);
  }

  function handleFailure(response) {
    createNotification('error', 'Sod off mate!');
  }

  function change() {
    setToggle(!toggle);
    props.toggleFunction(toggle);
  }


  return (
    <header id='header'>
      <div className='intro' style={{ background: "url(https://res.cloudinary.com/oneverify/image/upload/v1713979429/Capture_omey2j.png)", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%", }}>
        {/* <ParticlesBg type="circle" bg={{ zIndex: 0, position: "absolute", top: 0 }} /> */}
        <div className='overlay'>
          <div className='container'>

            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                {visibility && <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Write a Blog Yourself"
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={false}
                />}
                {
                  !visibility && <button
                    className='btn btn-custom btn-lg page-scroll'
                    onClick={change}
                  >
                    Toggle
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
